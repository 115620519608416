#animated__bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../images/bg7.png');
    background-size:cover;
    background-position: bottom;

          -webkit-animation: slidein 75s;
          animation: slidein 200s;
  
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  
          -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  
          -webkit-animation-direction: alternate;
          animation-direction: alternate;              
  }
  
  @-webkit-keyframes slidein {
  from {background-position: bottom; background-size:3000px; }
  to {background-position: 0px 0px;background-size:2750px;}
  }
  
  @keyframes slidein {
  from {background-position: bottom;background-size:3000px; }
  to {background-position: 0px 0px;background-size:2750px;}
  
  }
  