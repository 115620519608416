@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;0,500;0,700;1,400;1,700&display=swap');

:root {
  --white: #FFFFFF;
  --white-100: #E5E1EE;
  --dark: #071108;
  --primary: #313783;
  --accent: #2d6fa5;

  --primary-font: 'Poppins', sans-serif;

  /* From https://css.glass */
  /*background
  border-radius
  box-shadow
  backdrop-filter
  -webkit-backdrop-filter
  border*/
  --glass-bg: rgba(255, 255, 255, 0.322);
  --glass-br: 16px;
  --glass-bs: 0 4px 30px rgba(0, 0, 0, 0.1);
  --glass-bf: blur(5px);
  --glass-wk-bf: blur(5px);
  --glass-border: 1px solid rgba(255, 255, 255, 0.3);

  --shadow: 5px 5px 10px #0000002f;
}

html,body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
}

body {
  background: var(--white-100);
}

* {
  margin: 0;
  padding: 0;
}

.page__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #a7c9f7;
}

.page__wrapper {
  width: 95%;
  z-index: 1;
  min-height: 400px;
  background: var(--glass-bg);
  border-radius: var(--glass-br);
  box-shadow: var(--glass-bs);
  backdrop-filter: var(--glass-bf);
  -webkit-backdrop-filter: var(--glass-wk-bf);
  border: var(--glass-border);
  
}

.button__style__1 {
  padding: 0.5em 1em;
  font-family: var(--primary-font);
  border: none;
  border-radius: 2em;
  display: block;
  width: fit-content;
  text-decoration: none;
  font-size: 18px;
  background-color: var(--white);
  border: 3px solid var(--white);
  color: var(--dark);
  box-shadow: var(--shadow);
  transition: 0.2s;
}

@media screen and (max-width: 850px) {
  .button__style__1 {
    font-size: 16px;
    padding: 0.4em 0.8em;
  }
}

.button__style__1:hover {
  background-color: var(--white-100);
}

h1 {
  font-family: var(--primary-font);
  font-size: 48px;
  color: var(--dark);
  line-height: 1em;
  margin-bottom: 0.5em;
  margin-top: 1em;
}

h2 {
  font-family: var(--primary-font);
  font-size: 32px;
  color: var(--dark);
  line-height: 1em;
  margin-bottom: 0.5em;
  margin-top: 1em;
}

p {
  margin-bottom: 1em;
  font-family: var(--primary-font);
  font-size: 18px;
  color: var(--dark);
}

.general__page__content__wrapper {
  width: 80%;
  padding-top: 80px;
  padding-bottom: 20px;
  max-width: 1200px;
  background-color: var(--white-100);
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.226);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.712);
  border-radius: var(--glass-br);
  box-shadow: var(--glass-bs);
  backdrop-filter: var(--glass-bf);
  -webkit-backdrop-filter: var(--glass-wk-bf);
  border: var(--glass-border);
}

.general__page__content__wrapper h2 {
  text-align: left;
  width: 80%;
}

.general__page__content__wrapper p {
  width: 80%;
}

.floating__image__right {
  width: 80%;
}

.floating__image__right p {
  width: 100%;
}

.floating__image__right .floating__right__image {
  float: right;
  max-width: 40%;
  max-height: 400px;
  margin-left: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 850px) {
  .floating__image__right .floating__right__image {
    max-width: 60%;
  }
  h1 {
    font-size: 32px;

  }
  
  p {
  font-size: 16px;
  }
  h2 {
    font-size: 24px;
  }
}

@media screen and (max-width: 600px) {
  .floating__image__right .floating__right__image {
    max-width: 100%;
    margin-left: 0;
    float: none;
  }
}

.compare__wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.compare__item {
  margin: 10px;
  width: 320px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 450px) {
  .compare__item {
    width: 280px;
  }
}

.compare__item h3 {
  text-align: center;
  background-color: var(--white-100);
  width: fit-content;
  padding: 0.25em 1.5em;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-family: var(--primary-font);
  border: 3px solid var(--accent);
  border-bottom: none;
  color: var(--primary)
}


.compare__item ul {
  list-style-type: none;
  font-family: var(--primary-font);
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  border-radius: var(--glass-br);
  box-shadow: var(--glass-bs);
  backdrop-filter: var(--glass-bf);
  -webkit-backdrop-filter: var(--glass-wk-bf);
  border: var(--glass-border);
  border: 3px solid var(--accent);
}

.compare__item ul li {
  width: 95%;
  margin: 0.5em 0;
}

.podcastembed {
  width: 80%;
}

.buttonArray {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}

.buttonArray a {
  display: block;
  background-color: var(--accent);
  width: fit-content;
  margin-right: 10px;
  padding: 0.5em 1em;
  border-radius: 15px;
  font-family: var(--primary-font);
  text-decoration: none;
  color: white;
  transition: 0.2s;
  margin-bottom: 0.5em;

}

.buttonArray a:hover {
  background-color: var(--primary);
}

.buttonCenter {
  justify-content: center;
}

.general__page__content__wrapper .home__three__box__item {
  margin-top: 20px;
  height: 350px;
}

@media screen and (max-width: 1250px) {
  .general__page__content__wrapper .home__three__box__item p {
    font-size: 14px;
  }  
}

@media screen and (max-width: 1100px) {
  .general__page__content__wrapper .home__three__box__item {
    height: 200px;
  }  
}


@media screen and (max-width: 680px) {
  .general__page__content__wrapper .home__three__box__item {
    height: 300px;
  }  
}

@media screen and (max-width: 450px) {
  .general__page__content__wrapper .home__three__box__item {
    height: auto;
  }  
}


.general__page__content__wrapper .membership .home__three__box__item:hover {
  background-color: var(--primary);
}

.Collapsible {
  width: 80%;
  margin-bottom: 5px;
}

.Collapsible__trigger {
  background-color: var(--primary);
  color: var(--white);
  font-family: var(--primary-font);
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  cursor: pointer;
}

.Collapsible__contentInner {
  background-color: var(--white);
  padding: 1em;
}

.Collapsible__trigger.is-open svg {
  transform: rotate(180deg);
}