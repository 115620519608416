footer {
    width: 100%;
    background-color: var(--accent);
    border-top: 2px solid var(--primary);
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 20px 0;
    flex-direction: column;
}

.footer__items {
    display: flex;
    width: 90%;
    max-width: 1200px;
    justify-content: space-between;
    color: var(--white);
    align-items: center;
}

.changeFooterColor:hover,
.changeFooterColor a:hover {
    color: var(--white-100);
}

.footer__items li {
    margin-bottom: 0.5em;
}

.footer__items p {
    font-size: 32px;
    line-height: 0.9;
    font-weight: 800;
    color: var(--white);
    margin-bottom: 0;
    margin: 0;
}

.footer__items ul {
    list-style-type: none;
    font-family: var(--primary-font)
}

.footer__items ul a {
    color: var(--white);
    text-decoration: none;
}

.footer__items ul svg {
    margin-right: 5px;
    width: 12px;
}

.footer__socials { 
    display: flex;
    justify-content: flex-end;
    width: 90%;
    max-width: 1200px;
    margin-top: 20px;
}

.footer__socials svg {
    color: var(--white);
}

.footer__socials a {
    margin-left: 20px;
}

@media screen and (max-width: 900px) {
    .footer__items {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .footer__items p {
        margin-bottom: 20px;
        font-size: 24px;
    }
}

@media screen and (max-width: 500px) {
    .footer__items ul {
        font-size: 14px;
    }
}

@media screen and (max-width: 370px) {
    .footer__items ul {
        font-size: 12px;

    }
}