.glass__cta {
    min-height: 400px;
    padding-top: 70px;
    padding-bottom: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    
}

.glass__cta__text {
    width: 40%;
}

.glass__cta__graphic {
    width: 500px;
    height: 500px;
    transform: rotate(5deg);
}

.glass__cta__graphic img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    box-shadow: var(--shadow);

}

@media screen and (max-width: 1000px) {
    .glass__cta__graphic {
        display: none;
    }

    .glass__cta__text {
        width: 80%;
    }
    .glass__cta {
        display: flex;
        justify-content: center;
    }
}