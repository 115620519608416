.swiper {
    width: 80%;
    height: 100%;
  
  }


  .testimonials__section {
    margin-bottom: 50px;
    position: relative;
  }

.testimonials__section h2 {
    text-align: center;
    color: white;
    text-align: center;
    font-size: 48px;
    margin-bottom: 1em;
    background: -webkit-linear-gradient(180deg, #cbd1f8, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
  
.swiper  .swiper-slide {
    text-align: center;
    font-size: 18px;
    height: 450px;
    border-radius: 20px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.2s;
    flex-direction: column;
  }

.swiper-slide span {
    position: absolute;
    top: 0px;
    left: 10px;
    font-size: 84px;
    color: var(--accent)
}

.swiper-slide .swiper__text__wrapper {
    margin-top: 50px;
    width: 80%;
}

.swiper-slide .swiper__text__wrapper p {
    font-size: 14px !important; 
    text-align: left;
}
.swiper-slide {
    opacity: 0.25;
}

.swiper-slide-prev,
.swiper-slide-next {
    opacity: 0.5;
}

.swiper-slide-active {
    opacity: 1;
}
.swiper-slide .swiper__text__wrapper .author {
    bottom: 0px;
    font-size: 32px;
    width: fit-content;
}

@media screen and (max-width: 600px) {
    .swiper {
        width: 100%;
  }

  .swiper  .swiper-slide{
    height: 525px;
  }

  .testimonials__section h2 { 
    font-size: 32px;
  }
}

.button-wrapper-testimonials {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 70%;
  z-index: 20;
}

.button-wrapper-testimonials button {
  background: none;
  border: none;
}

.button-wrapper-testimonials button svg {
  color: var(--white);
  stroke: var(--accent);
  stroke-width: 15px;

  transform: scale(5);
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .button-wrapper-testimonials {
    display: none;
  }
}