.header__graphic h1 {
    color: white;
    text-align: center;
    font-size: 64px;
    margin-bottom: 0.25em;
    background: -webkit-linear-gradient(180deg, #cbd1f8, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 900px) {
    .header__graphic h1 {
        font-size: 48px;
    }
}

@media screen and (max-width: 600px) {
    .header__graphic h1 {
        font-size: 32px;
    }
}

.header__graphic {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    flex-direction: column;
}

.header__graphic p {
    color: white;
    text-align: center;
    background: -webkit-linear-gradient(180deg, #cbd1f8, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 5px;
    font-weight: 900;
    font-style: italic; 
    z-index: 2;
}

iframe {
    aspect-ratio: 16 / 9;
    height: auto;
    width: 100%;
}

.video__container {
    width: 85%;
    max-width: 1000px;
    box-sizing: border-box;
    border: 3px solid var(--primary);
    margin-bottom: -80px;
    border-radius: 10px;
    background: var(--glass-bg);
    border-radius: var(--glass-br);
    box-shadow: var(--glass-bs);
    backdrop-filter: var(--glass-bf);
    -webkit-backdrop-filter: var(--glass-wk-bf);
    border: var(--glass-border);
    padding: 10px;
    z-index: 3;
}