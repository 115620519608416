#main__navbar {
    width: 85%;
    height: 90px;
    margin-top: 20px;
    margin-bottom: -30px;
    z-index: 10;

    background: var(--glass-bg);
    border-radius: var(--glass-br);
    box-shadow: var(--glass-bs);
    backdrop-filter: var(--glass-bf);
    -webkit-backdrop-filter: var(--glass-wk-bf);
    border: var(--glass-border);

    display: flex;
    justify-content: space-around;
    align-items: center;
}


#main__navbar__wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.navbar__links {
    display: flex;
    align-items: center;
    list-style-type: none;
    width: 60%;
    justify-content: space-between;
}

.navbar__links li {
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}

.navbar__links li a {
    color: var(--dark);
    text-decoration: none;
    transition: 0.2s;
}

.navbar__links li a:hover {
    color: var(--accent);
}

.navbar__buttons {
    list-style-type: none;
    display: flex;
    flex-direction: column;

}

.navbar__buttons li:hover {
    background-color: var(--white-100);
} 

.navbar__buttons li {
    text-align: center;
    border: none;
    background: var(--white);
    padding: 0.25em;
    width: 220px;
    border-radius: 20px;  
    margin: 0.2em 0;
    box-shadow: var(--shadow);
    font-family: var(--primary-font);
    font-size: 16px;
    transition: 0.2s;
}

.navbar__buttons li a {
    text-decoration: none;
    color: var(--dark)
}
.logo-ref {
    display:inline;
    height: 100%;
    display: flex;
    align-items: center;
}
.navbar__logo {
    max-height: 90%;
}

.navbar__hide__open {
    display: none;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
}

.navbar__hide__open span {
    width: 40px;
    height: 3px;
    border-radius: 3px;
    display: block;
    background-color: black;
}

@media screen and (max-width: 1300px) {
    .navbar__links li {
        font-size: 14px;
    }

    .navbar__links {
        width: 70%;
    }
}

@media screen and (max-width: 1100px) {

    #main__navbar__wrapper {
        position: fixed;
        top: 0;
        width: 100%;
        height: 50px;
        z-index: 100000;
        display: flex;

    }
    #main__navbar {
        height: inherit;
        margin: 0;
        width: 100%;
        background-color: var(--white-100);
        border-radius: 0;
    }

    #main__navbar .navbar__logo {
        width: 150px;
    }

    #main__navbar .navbar__links {
        flex-direction: column;
        position: fixed;
        justify-content: left;
        
        width: 100vw;
        height: 0;
        overflow: hidden;
        top: 50px;
        background-color: var(--accent);
        transition: 0.5s;
    }

    #main__navbar .navbar__links li {
        margin-top: calc((80vh - (50px 
        + (1em * 7)) )/7 )
    }

    #main__navbar .navbar__links .navbar__buttons li {
        margin-top: 10px;
    }

    #main__navbar .navbar__links.active {
        height: calc(100vh - 50px);
    }

    .navbar__hide__open span {
        transition: 0.3s;
    }

    .navbar__hide__open {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .navbar__hide__open .bar1 {
        top: 10px;
        position: absolute;
    }

    .navbar__hide__open .bar3 {
        position: absolute;
        bottom: 10px;
    }

    .navbar__hide__open.active .bar1 {
        transform: rotate(45deg);
        position: absolute;
        top: 23px;
    }
    .navbar__hide__open.active .bar2 {
        opacity: 0;
        transform: translateX(-20px)
    } 
    .navbar__hide__open.active .bar3 {
        transform: rotate(-45deg);
        position: absolute;
        bottom : 24px;
    }


    
    .navbar__links li a {
    color: var(--white)
}


.navbar__buttons li a {
    color: var(--dark)
}



    .navbar__links li{
        opacity: 0;
        transition: 0.3s;
    }

    .navbar__links.active li {
        opacity: 1;
        transition-delay: 0.3s;
    }
    
}